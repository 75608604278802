<template>
  <Portal
    to="subModals"
    :disabled="!usePortal"
  >
    <b-modal
      v-model="modalOpen"
      cancel-disabled
      ok-disabled
      :title="modalTitle"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="hideModal"
      hide-footer
      header-close-content=""
    >
      <template v-if="method === 'phone'">
        <VerificationPhoneForm :action="action" :phone="profile.phone" @verified="verify" />
      </template>
      <template v-else-if="method === 'authenticator'">
        <VerificationAuthForm :action="action" @verified="verify" />
      </template>
      <template v-else-if="method === 'email'">
        <VerificationEmailForm :action="action" :email="profile.email" @verified="verify" />
      </template>
      <template v-else>
        <div
          class="alert alert-danger"
          role="alert"
        >
          {{ $t('mfa.error_reload') }}
        </div>
      </template>
      <div slot="modal-footer" />
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import VerificationAuthForm from '../forms/VerificationAuthForm'
import VerificationPhoneForm from '../forms/VerificationPhoneForm'
import VerificationEmailForm from "@/components/forms/VerificationEmailForm.vue";

export default {
  name: 'MfaVerificationForm',
  components: {
    VerificationEmailForm,
    VerificationAuthForm,
    VerificationPhoneForm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      modalOpen: this.show
    }
  },
  computed: {
    modalTitle () {
      if (this.method === 'phone') {
        return this.$t('mfa.sms_title2')
      } else if (this.method === 'authenticator') {
        return this.$t('mfa.app_title2')
      } else if (this.method === 'email') {
        return this.$t('mfa.email_title2')
      } else {
        return ''
      }
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
    show (val) {
      this.modalOpen = val
    }
  },
  methods: {
    verify (token) {
      this.$emit('verified', token)
    },
    hideModal () {
      this.$emit('hidden')
    }
  }
}
</script>
