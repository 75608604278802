<template>
  <Loader :loading="isLoading">
    <div
      v-if="phone"
      class="alert alert-elevate alert-light"
    >
      <div class="alert-text">
        {{ $t('mfa.sms_phone') }} <b>{{ phone }}</b>
      </div>
    </div>
    <b-form
      class="kt-form form-inline d-flex justify-content-center"
      @submit.prevent="verify"
    >
      <div style="display: block;width: 100%">
        <div
          v-if="message"
          class="alert"
          :class="{
            'alert-danger': messageType === 'error',
            'alert-success': messageType === 'success',
            'alert-warning': messageType === 'warning'
          }"
        >
          {{ message }}
        </div>
      </div>

      <label
        for="code"
        class="text-bold text-dark text-center"
        style="display: block"
      >
        {{ $t('mfa.sms_title3') }}
      </label>
      <div class="input-group">
        <MaskedInput
          id="code"
          ref="codeInput"
          v-model="code"
          type="text"
          class="form-control input-lg text-center mb-sm"
          placeholder="1 - 2 - 3 - 4 - 5 - 6"
          :mask="[/\d/, ' ', '-', ' ', /\d/, ' ', '-', ' ', /\d/, ' ', '-', ' ', /\d/, ' ', '-', ' ', /\d/, ' ', '-', ' ', /\d/]"
          :guide="true"
          placeholder-char="_"
          @keydown.delete="inputKeydown"
        />
        <button
          type="submit"
          class="btn btn-brand kt-margin-l-5"
        >
          {{ $t('general.submit') }}
        </button>
      </div>
    </b-form>
    <div class="kt-separator kt-separator--space-md kt-separator--border-dashed" />
    <h6 class="text-center">
      <a
        href="#"
        @click.prevent="showResend"
      >
        {{ $t('mfa.sms_delay') }}
      </a>
    </h6>
    <div
      v-if="resend"
    >
      <div class="text-center">
        <a
          href="#"
          class="btn btn-info mr-1"
          @click.prevent="requestToken"
        >
          {{ $t('mfa.sms_resend') }}
        </a>&nbsp;
        <a
          href="#"
          class="btn btn-info"
          @click.prevent="call"
        >
          {{ $t('mfa.sms_call') }}
        </a>
      </div>
    </div>
  </Loader>
</template>

<script>
import { getNumeric } from '../../helpers/strings'
import Loader from '../global/Loader'

export default {
  name: 'VerificationPhoneForm',
  components: { Loader },
  props: {
    action: {
      type: String,
      required: false
    },
    phone: {
      type: String,
      required: true
    },
    phoneActivation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      code: '',
      isLoading: true,
      token: null,
      message: null,
      messageType: 'error',
      messageId: null,
      timer: null,
      resend: false,
      type: 'sms'
    }
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    }
  },
  created () {
    this.requestToken()
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.codeInput.$refs.input.focus()
    })
  },
  methods: {
    showResend () {
      this.resend = !this.resend
    },
    call () {
      this.message = null
      this.type = 'call'
      this.isLoading = true
      if (this.timer) {
        clearTimeout(this.timer)
      }

      let url = null
      if (!this.phoneActivation) {
        url = this.$api.mfaAction + '/' + this.action
        if (this.type === 'call') {
          url = url + '?call=1'
        }
      } else {
        url = this.$api.phoneAuthenticatorEnable + '/' + this.type
      }

      axios.get(url).then(response => {
        this.token = response.data.data.token
        this.messageId = response.data.data.message_id
        this.type = response.data.data.type
        this.isLoading = false
        this.checkStatus()
      }).catch(error => {
        this.messageType = 'error'
        if (error.response.status === 429) {
          this.message = error.response.data.data.message
        } else if (error.response.status === 500 || error.response.status === 422) {
          if(error.response.data.message){
            this.message = error.response.data.message
          }else if(error.response.data.data.message){
            this.message = error.response.data.data.message
          }else{
            this.message = this.$t('mfa.sms_error')
          }
        }
        this.isLoading = false
      })
    },
    requestToken () {
      this.message = null
      this.type = 'sms'
      this.isLoading = true
      if (this.timer) {
        clearTimeout(this.timer)
      }

      let url = null
      if (!this.phoneActivation) {
        url = this.$api.mfaAction + '/' + this.action
        if (this.type === 'call') {
          url = url + '?call=1'
        }
      } else {
        url = this.$api.phoneAuthenticatorEnable + '/' + this.type
      }

      axios.get(url).then(response => {
        this.token = response.data.data.token
        this.messageId = response.data.data.message_id
        this.type = response.data.data.type
        // this.debugCode = response.data.data.debug_code;
        this.isLoading = false
        this.checkStatus()
      }).catch(error => {
        this.messageType = 'error'
        if (error.response.status === 429) {
          this.message = error.response.data.data.message
        } else if (error.response.status === 500 || error.response.status === 422) {
          if(error.response.data.message){
            this.message = error.response.data.message
          }else if(error.response.data.data.message){
            this.message = error.response.data.data.message
          }else{
            this.message = this.$t('mfa.sms_error')
          }
        }
        this.isLoading = false
      })
    },
    verify () {
      if (this.code) {
        this.message = null
        this.isLoading = true
        if (this.timer) {
          clearTimeout(this.timer)
        }

        let url = null
        if (!this.phoneActivation) {
          url = this.$api.mfaAction
        } else {
          url = this.$api.phoneAuthenticatorEnable
        }

        axios.post(url, { code: getNumeric(this.code), token: this.token, action: this.action }).then((response) => {
          if (response.data.data.status === 'verified' || response.data.data.status === 'phone_verified') {
            this.$emit('verified', this.token)
          }
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          if(error.response.status === 500){
            this.messageType = 'error'
            this.message = this.$t('mfa.sms_error2')
          }else{
            this.messageType = 'error'
            this.message = this.$t('mfa.code_invalid')
          }
        })
      }
    },
    checkStatus () {
      if (this.messageId) {
        axios.get(`${this.$api.phoneMessageStatus}/${this.type}/${this.messageId}`).then((response) => {
          this.isLoading = false
          this.message = response.data.data.message
          if (response.status === 201) {
            this.messageType = 'success'
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
              this.message = ''
            }, 5000)
          } else if (response.status === 202) {
            this.messageType = 'warning'
            this.timer = setTimeout(() => this.checkStatus(), 5000)
          }
        }).catch((error) => {
          this.messageType = 'error'
          if (error.response && error.response.status === 500) {
            if(error.response.data.message){
              this.message = error.response.data.message
            }else if(error.response.data.data.message){
              this.message = error.response.data.data.message
            }else{
              this.message = this.$t('mfa.sms_error')
            }
          }
        })
      }
    },
    resendCode () {
      clearTimeout(this.timer)
      this.requestToken()
    },
    inputKeydown (event) {
      // let value = event.target.value
      // let char = value[value.length - 1]
      // if (isNaN(parseInt(char)) || char === ' ' || char === '_' || char === '-') {
      //   event.target.value = value.substring(0, value.length - 1)
      // }
    }
  }
}
</script>
