<template>
  <b-form-group
    :description="description"
    :label="label"
    :label-for="id"
    :invalid-feedback="errorMessage"
    :state="state"
    :label-cols-xl="cols === 0 ? null : cols"
    :label-cols-lg="cols === 0 ? null : cols"
  >
    <template slot="description">
      <slot>
        <div class="form-text kt-font-bold">
          <div v-html="description" />
        </div>
      </slot>
    </template>
    <template v-if="type === 'text' || type === 'email' || type === 'password' || type === 'number'">
      <b-input-group>
        <b-input-group-prepend v-if="prepend">
          <span class="input-group-text" v-html="prepend"></span>
        </b-input-group-prepend>
        <b-form-input
          :id="id"
          v-model="content"
          :state="state"
          :type="type"
          :name="name"
          :required="required"
          :autocomplete="autocomplete"
          :readonly="readonly"
          :placeholder="placeholder"
          :min="min"
          :max="max"
          :disabled="disabled"
          @input="handleInput"
          @change="handleChange"
        />
        <b-input-group-append v-if="append">
          {!! append !!}
        </b-input-group-append>
      </b-input-group>
    </template>
    <template v-if="type === 'textarea'">
      <b-textarea
        :id="id"
        v-model="content"
        :state="state"
        :name="name"
        :required="required"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :placeholder="placeholder"
        @input="handleInput"
        @change="handleChange"
        rows="4"
        :disabled="disabled"
      >{{ content }}</b-textarea>
    </template>
    <template v-if="type === 'phone'">
      <div>
        <PhoneInput
          ref="phoneInput"
          v-model="content"
          :name="name"
          :input-class="{'is-invalid': hasErrors}"
          :readonly="readonly"
          :required="required"
          :disabled="disabled"
          @input="handleInput"
        />
      </div>
    </template>
    <template v-if="type === 'select'">
      <div>
        <b-form-select
          :id="id"
          v-model="content"
          :options="options"
          :state="state"
          :name="name"
          :required="required"
          :autocomplete="autocomplete"
          :readonly="readonly"
          :placeholder="placeholder"
          :disabled="options.length === 0 || disabled"
          @input="handleInput"
          @change="handleChange"
        />
      </div>
    </template>
  </b-form-group>
</template>

<script>
import PhoneInput from '../global/PhoneInput'

export default {
  name: 'AccountInput',
  components: {
    PhoneInput
  },
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    id: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    cols: {
      type: Number,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    append: {
      type: String,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    hasErrors: {
      cache: false,
      get () {
        return this.errors.hasOwnProperty(this.name) && Array.isArray(this.errors[this.name]) && this.errors[this.name].length > 0
      }
    },
    errorMessage () {
      if (this.hasErrors) {
        return this.errors[this.name][0]
      }
      return ''
    },
    state: {
      cache: false,
      get () {
        return this.hasErrors ? false : null
      }
    }
  },
  watch: {
    value (newValue) {
      this.content = newValue
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    },
    handleChange () {
      this.$emit('change', this.content)
    },
    countryCode () {
      return this.$refs.phoneInput.countryCode ? this.$refs.phoneInput.countryCode.toUpperCase() : null
    },
    isValidPhone () {
      return this.$refs.phoneInput.isValid()
    }
  }
}
</script>
