<template>
  <Loader :loading="isLoading">
      <div
        v-if="error"
        class="alert alert-danger"
      >
        {{ error }}
      </div>
      <label
        for="code"
        class="text-bold text-dark text-center"
        style="display: block"
      >
        {{ $t('mfa.app_title') }}
      </label>

      <b-form
        class="kt-form form-inline d-flex justify-content-center"
        @submit.prevent="verify"
      >
        <div class="input-group">
          <MaskedInput
            id="code"
            ref="codeInput"
            v-model="code"
            type="text"
            class="form-control input-lg text-center mb-sm"
            placeholder="123 456"
            :mask="[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]"
            :guide="false"
            placeholder-char="_"
            @keydown.delete="inputKeydown"
          />
        </div>
        <button class="btn btn-brand kt-margin-l-5">{{ $t('general.submit') }}</button>
      </b-form>
  </Loader>
</template>

<script>
import { getNumeric } from '../../helpers/strings'
import Loader from 'js/components/global/Loader'
export default {
  name: 'VerificationAuthForm',
  components: { Loader },
  props: {
    action: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      code: '',
      error: '',
      isLoading: true,
      token: null
    }
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    }
  },
  created () {
    axios.get(this.$api.mfaAction + '/' + this.action).then(response => {
      this.token = response.data.data.token
      this.isLoading = false
    }).catch(error => {

    })
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.codeInput.$refs.input.focus()
    })
  },
  methods: {
    verify () {
      if (this.code) {
        this.isLoading = true
        axios.post(this.$api.mfaAction, { code: getNumeric(this.code), token: this.token, action: this.action }).then((response) => {
          if (response.data.data.status === 'verified') {
            this.$emit('verified', this.token)
          }
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.error = this.$t('mfa.code_invalid')
        })
      }
    },
    inputKeydown (event) {
      let value = event.target.value
      let char = value[value.length - 1]
      if (isNaN(parseInt(char)) || char === ' ') {
        event.target.value = value.substring(0, value.length - 1)
      }
    }
  }
}
</script>
