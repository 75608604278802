<template>
  <!-- begin:: Footer -->
  <div class="kt-footer kt-grid__item" id="kt_footer">
    <!--<div class="kt-container kt-container--fluid kt-margin-b-20">
      <trustpilot/>
    </div>-->
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-footer__wrapper">
        <div class="kt-footer__copyright">
          2019-2022&nbsp;&copy;&nbsp;YieldNodes
        </div>
        <div class="kt-footer__menu">
          <template v-if="$store.state.authUser.authenticated">
            <router-link :to="{name: 'faq'}" class="kt-link">{{ $t('title.contact') }}</router-link>
          </template>
          <template v-else>
            <a href="https://yieldnodes.com/" target="_blank" class="kt-link">{{ $t('title.contact') }}</a>
          </template>
          <router-link :to="{name: 'risk'}" target="_blank" class="kt-link">{{ $t('title.risk') }}</router-link>
          <router-link :to="{name: 'terms'}" target="_blank" class="kt-link">{{ $t('title.terms') }}</router-link>
          <router-link :to="{name: 'privacy'}" target="_blank" class="kt-link">{{ $t('title.privacy') }}</router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import Trustpilot from "../global/Trustpilot";
export default {
  components: {Trustpilot}
}
</script>
