<template>
  <!-- TrustBox widget - Horizontal -->
  <div ref="trustbox" class="trustpilot-widget" data-locale="en-US" data-template-id="5406e65db0d04a09e042d5fc" data-businessunit-id="5f1e6bd24cda2c0001c87f6a" data-style-height="28px" data-style-width="100%" data-theme="light">
    <a href="https://www.trustpilot.com/review/yieldnodes.com" target="_blank" rel="noopener">Trustpilot</a>
  </div>
  <!-- End TrustBox widget -->
</template>

<style>

</style>

<script>
export default {
  name: 'Trustpilot',
  components: {
  },
  data () {
    return {

    }
  },
  computed: {
  },
  mounted: function () {
    //Trustpilot.loadFromElement(this.$refs.trustbox);
  },
  methods: {

  }
}
</script>
