<template>
  <div>
    <h1 class="kt-margin-b-20 text-center">YieldNodes / Decenomy Participant Audit</h1>
    <h5>Date: July 19-21, 2022</h5>
    <h5 class="kt-margin-b-30">Location: Malta - Decenomy Offices and Decenomy HUB</h5>

    <p>We are happy to announce that the Funds Audit which took place in Malta has concluded with a very pleasing result!</p>

    <p>As of July 19, 2022, YieldNodes/Decenomy assets are currently <b>over-collateralized by more than 30%</b>. This means the value of coins held (all of the Decenomy coins plus BTC and exterior wallets) exceeded all liabilities (deposits and compounds) <b>by more than 30%</b>.

    <p>In order to reach this conclusion, the auditors were given transparent access to every wallet in order to calculate the values held and compare against our obligations.

    <p>First, we showed the members area with all deposits and compounds, and explained how the system is operated on a day-to-day basis. To prove that the data was live and actually on the server, we asked if any of the auditors would be willing to show their account live and confirm the numbers, to which <b>Liam D.</b> agreed.</p>

    <p>He confirmed the that deposits, withdrawals and other details of his account to be genuine.</p>

    <p>Afterwards, Urs (the Architect and leader of Decenomy) showed an Excel sheet that held every position and sum - directly linked to the wallets and exchange accounts.</p>

    <p>I suggested that positions be picked at random to verify the sums, but auditor James Pelton suggested viewing ALL positions. This extended the time required for the audit but, in retrospect, was a much better option as it left zero doubt regarding asset availability.</p>

    <h3 class="kt-margin-b-15 kt-margin-t-25">Disclosure on Auditors:</h3>

    <p>All <b>10 auditors</b> are active, funded YieldNodes members who were required to cover their own travel expenses.</p>

    <p>The auditors <b>received no payment</b> or other incentive for their services beyond hotel accommodation and food.</p>

    <p>None of the auditors is an employee of YieldNodes or Decenomy.</p>

    <h3 class="kt-margin-b-15 kt-margin-t-25">Connections between Auditors:</h3>

    <p>With the exeption of James Pelton and Kris Mccauley (both active YouTubers), none of the auditors knew each other prior to arriving in Malta. James and Kris reconnected during the visit as they know each other, but did not know that they had both been invited.</p>

    <p>Due to cancellations by previous invitees, Poppy Todd and Matty Leidecker were randomly selected prior to the audit via transparent randomizing on YouTube.</p>

    <p>However, we were left with just 9 auditors due to a last-minute cancellation, so Liam D. suggested we invite Bo Schjønning-Larsen as he lives in Malta and is a YieldNodes Participant. This was agreed, and thus filled all 10 seats.</p>

    <img src="~images/auditors/3/all.jpg" class="m-1 rounded mx-auto img-fluid">

    <h3 class="kt-margin-b-15 kt-margin-t-25">YieldNodes/ Decenomy Hub welcomes the Audit :)</h3>

    <p>A walk-through of the Decenomy HUB during a break in the audit: (filmed by Liam D.)</p>

    <div class="kt-margin-b-15">
      <b-embed
        type="iframe"
        src="https://www.youtube.com/embed/W1k7A1EjPRs"
        aspect="16by9"
        allowfullscreen />
    </div>

    <h3 class="kt-margin-b-15 kt-margin-t-25">Here are the auditors and their signed agreements confirming what they saw:</h3>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Adrian Edward Steven</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/adrian.jpg" class="m-1 rounded float-right img-fluid" alt="">My name is Adrian Edward Steven. I live in Johannesburg, South Africa. I am married and my wife works for one of the large banks in South Africa. I am also a father of 2 daughters.<br>
        <br>I am a full time crypto currency enthusiast and have been so since 2013. I have been involved in quite a few crypto currency projects but none with the consistency and transparency of Yield Nodes. I have been an active member of Yield Nodes since September 2021. I was honoured to be invited to Yield Nodes and Decenomy Audit in Malta recently. We were treated extremely well by the entire team and made to feel extremely welcome. The transparency and professionalism of the audit was of a very very high standard. I am more than satisfied and feel privileged to be a member where my funds have been growing each and every single month consistently. I am excited about my future with yield nodes as an investor and will now be acquired some of their highly prized and acclaimed sapphire coin.</p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/adrian.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Bo Schjønning-Larsen</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/bo.jpg" class="m-1 rounded float-right img-fluid" alt="">
          YouTube Channel: <a href="https://hitechinvestments.reviews/Crypto" target="_blank" rel="nofollow">https://hitechinvestments.reviews/Crypto</a><br>
          Website: <a href="https://www.hi-tech-investments.com/" target="_blank" rel="nofollow">https://hi-tech-investments.com/</a><br>
          <br>
          Best Regards,<br>
          Bo Schjønning-Larsen
        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/bo.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Poppy Todd</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/poppy.jpg" class="m-1 rounded float-right img-fluid" alt="">
          I have worked as a UK civil servant for nearly 10 years. My role covers data quality, risk management and internal investigations.<br>
          <br>
          I have a keen interest in all things Crypto and have held various cryptocurrencies since 2016. Fed up with my fiat savings depreciating in value, I decided to invest with the Yieldnodes programme to hopefully generate some regular, passive income.<br>
          <br>
          Having seen multiple crypto projects fail this year, I applied for the 2022 audit to better understand the longevity of this programme.<br>
          <br>
          The Audit<br>
          <br>
          I was a randomly selected investor who attended the July 2022 Yieldnodes audit.<br>
          <br>
          While auditors received an excellent level of hospitality from the welcoming team, this was by no means inappropriate and at no point did I feel the team attempted to influence my views.<br>
          <br>
          During the morning of the audit I was shown live data including the amount of investor accounts, the amount of total funds and evidence of these funds in numerous wallets.<br>
          <br>
          The afternoon of the audit was a Decenomy presentation where Steve, Urs and Dima talked us through the ongoing work with Sao Tome and Principe and other projects to ensure Decenomy coins have real world use.<br>
          <br>
          Not only during the audit, but throughout my stay, the Decenomy team talked about their work openly, with confidence and enthusiasm. We were free to ask the team questions throughout and the transparency, which I’ve always associated with this venture, was even more apparent here.
        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/poppy.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Liam Dyson</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/liam.jpg" class="m-1 rounded float-right img-fluid" alt="">
          Leader of the YieldNodes Facebook Group (not affiliated)<br>
          <a href="https://www.otiumpiercing.co.uk" target="_blank" rel="nofollow">www.otiumpiercing.co.uk</a><br>
          <a href="https://facebook.com/groups/communitygroupyieldnodes/" target="_blank">https://facebook.com/groups/ communitygroupyieldnodes/</a><br>
          <br>
          I am first and foremost a family man. Nothing to me is more important. For work, I run a professional Body Piercing Studio and specialise in fine body Jewellery from high end manufactures......Otium Body Piercing. I also run a photography business and web design business also.<br>
          <br>
          What I am not: A Yield Nodes Fan Boy, a paid member of staff, or anything else the wonderful world of internet forums would like to call me. I am a stand alone investor into this company, with a huge passion for helping others and seeing them succeed.
        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/liam.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>James Pelton</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/james.jpg" class="m-1 rounded float-right img-fluid" alt="">
          I loved meeting with the team in Malta this week. One thing I can say with extreme confidence is that the team does not have any malicious intent and is truly looking out for the investor's best interests<br>
          <br>
          I can't give financial advice, but I feel that Yieldnodes is one of my best investments<br>
          <br>
          My Youtube Channel: <a href="https://www.youtube.com/c/jamespelton" target="_blank" rel="nofollow">https://youtube.com/c/jamespelton</a><br>
          My Twitter: <a href="https://twitter.com/jamespelton18" target="_blank" rel="nofollow">https://twitter.com/jamespelton18</a>
        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/james.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Joseph Giove</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/joseph.jpg" class="m-1 rounded float-right img-fluid" alt="">
          Joseph Giove is a philanthropist and Business, Career, Public Speaking, Wellness, Financial Advisor, and Life Coach. Joseph has developed a unique approach to business, wellness, investor, and performance optimization. For over 30 years he has been helping people in all areas of business and personal wellness including career coaching, public speaking, and life coaching.<br>
          <br>
          <a href="https://blackbookcrypto.com" target="_blank" rel="nofollow">https://blackbookcrypto.com</a>
        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/joseph.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Kris Mccauley</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/kris.jpg" class="m-1 rounded float-right img-fluid" alt="">
          Yieldnodes cooperated with all of the auditors and went above and beyond what any project in the crypto space has done. They not only answered all of our questions but sat down with anyone who wanted to ask questions afterwards. The team has decades of combined experience and I'm excited to see what this team can accomplish.<br>
          <br>
          <a href="https://www.youtube.com/c/KrisMcCauley" target="_blank" rel="nofollow">https://youtube.com/c/KrisMcCauley</a>
        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a class="disabled" target="_blank">Download Signed Agreement PDF (soon)</a></div>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet--bordered">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <b>Felix Schuldt</b>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><img src="~images/auditors/3/felix.jpg" class="m-1 rounded float-right img-fluid" alt="">
          Former Architect, who quit his day job for selling cat shirts via print on demand and made his name in the german speaking eCommerce community. He sold more than 1000 courses, and built several huge FB communities and SaaS's, such as flipstorm.com, threadbasket.com, merchninja.io, ninja-automation.de, the last ones he sold to an American company in 2021 for 1.8 million. Ever since he is focusing on new income streams and is moving from ecom to crypto. The newest saas he and his team is developing is Cryptaine an ICO comparison and rating tool + online academy for crypto.<br>
          <br>
          About the audit<br>
          <br>
          The Audit took place in the Decenomy Malta Hub, Steve provided a general outlook and insight about the conditions of yield nodes, his daily routine, and the backend dashboard of the yieldnodes admin section. After that Urs led us through all the wallets existing and made sure to show us in detail every single penny they had shown us before in an excel sheet. So I can 100% confirm. The money of all the investors plus round about 30% on top, is there. (I can not assure where that money came from, if it's lent, borrowed, or whatsoever).<br>
          <br>
          The team was super open for questions and the auditors themselves were interesting guys and girls from all corners of the world (big investors, business partners or influencers) We were all sitting around a table for 1 whole day to discuss the future visions of the decenomy crew while those questions were answered and future visions were presented.<br>
          <br>
          To be honest, I came there very sceptical as a small yieldnodes and masternodes investor (very low 6 figures). But I‘m leaving Malta with a clear understanding of the overall and 100 times even bigger picture of the decenomy eco system - so I‘m very looking forward to this concept and how that‘s going! But even more - I would love to be a part of it!<br>
          <br>
          Cya Soon!<br>
          <br>
          <a href="https://facebook.com/groups/addictedtocrypto" target="_blank" rel="nofollow" class="text-wrap">https://facebook.com/groups/ addictedtocrypto</a>

        </p>
        <div class="alert alert-secondary kt-margin-b-0">
          <div class="alert-icon"><i class="fas fa-download"></i></div>
          <div class="alert-text"><a href="/assets/audit/3/pdf/felix.pdf" target="_blank">Download Signed Agreement PDF</a></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--bordered">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                <b>Irfan Mohammed</b>
              </h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <p><img src="~images/auditors/3/irfan.jpg" class="m-1 rounded img-fluid d-block" alt="">
              Equity derivatives trading
            </p>
            <div class="alert alert-secondary kt-margin-b-0">
              <div class="alert-icon"><i class="fas fa-download"></i></div>
              <div class="alert-text"><a href="/assets/audit/3/pdf/irfan.pdf" target="_blank">Download Signed Agreement PDF</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--bordered">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                <b>Matti Leydecker</b>
              </h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <p><img src="~images/auditors/3/matti.jpg" class="m-1 rounded d-block img-fluid" alt="">

            </p>
            <div class="alert alert-secondary kt-margin-b-0">
              <div class="alert-icon"><i class="fas fa-download"></i></div>
              <div class="alert-text"><a href="/assets/audit/3/pdf/matti.pdf" target="_blank">Download Signed Agreement PDF</a></div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <p>We had fun - it was exciting to meet and greet in Malta, to take a trip through the old city of Valletta, and to hang out together!</p>

    <p><em>Disclaimer: We believe that what we have shown proves the concept worked well in the past but, as always, stay well diversified as a venture like YieldNodes/Decenomy carries a high degree of risk. Do not commit what you are not prepared to lose (we mean it!)</em></p>

    <p>We arranged this audit in the hope of providing transparency regarding what YieldNodes/Decenomy is (and isn't!), and are genuinely happy with the results.</p>

    <p>We promise to continue doing what we have always done, while being ethical and upfront about it.</p>

    <p><b>Q:</b> Does this mean Yieldnodes is a 100% safe opportunity and I should invest what I can?</p>
    <p><b>A:</b> Absolutely NOT! This Audit has been done to show that.</p>

    <h3 class="kt-margin-t-25 kt-margin-b-15 text-bold">WE PRACTICE WHAT WE PREACH!</h3>
    <p>Since the very start of YieldNodes in October 2019 we have generated an average of 10%+ monthly in value, and we wanted to give our members who stood with us some peace of mind. Talk is cheap, so we wanted to put the money where our mouth is.</p>

    <p>YieldNodes is going strong but it is still</p>

    <ul>
      <li>NOT regulated or supervised by any financial entity yet</li>
      <li>STILL a VERY RISKY participation model, and things can always go sour</li>
      <li>NOT trading Cryptos and NOT a Bitcoin pegged investment</li>
      <li>By NO MEANS a bank account or retirement fund!</li>
    </ul>

    <p>So, use the information we have provided to do your own risk assessment, and understand that funds are always at risk of being lost so there are absolutely NO guarantees.</p>

    <p>We do what we do with honesty and integrity, but it is as much a wild ride for us as it is for you, so read and absorb what you can and always ensure you can live with the decisions you make.</p>

    <p>That being said, we are grateful for your participation and humbled by your trust - we are ready and hungry to prove ourselves to you and the world …and to build out the decentralized economy that is the long term vision: <b>DECENOMY</b></p>

    <p>Sincerely,<br>
      <br>
      Urs & Steve<br>
      Yieldnodes Partners.</p>
    <br><br><br>
    <p><b>Warning!</b> Only Access your YieldNodes account from the official Url <b>https://yieldnodes.com</b> and <b>subdomains</b>! We do not yet use or support any Apps on iOS or Android! Beware of Scammers and Phishers trying to obtain your login info!<br>
      Login Page: <a href="https://members.yieldnodes.com/" target="_blank"><b>https://members.yieldnodes.com/</b></a><br>
      Tutorials: <a href="https://yieldnodes.medium.com/" target="_blank">https://yieldnodes.medium.com/</a><br>
      Community Run Facebook Group (Get Help all things Yieldnodes!): <a href="https://facebook.com/groups/communitygroupyieldnodes/" target="_blank">https://facebook.com/groups/communitygroupyieldnodes/</a><br>
      Institutional Investor? Apply <a href="https://members.yieldnodes.com/en/subscribe/ii" target="_blank">here</a><br>
      Like to understand the Risk better? <a href="https://members.yieldnodes.com/en/risk-assessment" target="_blank">https://members.yieldnodes.com/en/risk-assessment</a><br>
      Reviews: <a href="https://trustpilot.com/review/yieldnodes.com" target="_blank">https://trustpilot.com/review/yieldnodes.com</a><br>
      <br>
    YieldNodes & Decenomy Merchandise !<br>
      <a href="https://decenomy.myshopify.com/" target="_blank">https://decenomy.myshopify.com/</a><br>
      <br>
    NEW! YieldNodes & Decenomy Knowledge Database (learn Masternoding & Staking and much more!<br>
      <a href="https://knowledgebase.yieldnodes.com/" target="_blank">https://knowledgebase.yieldnodes.com/</a></p>

    <p>Disclaimer: All opinions and suggestions expressed by me are opinions. I am not a financial advisor nor is YieldNodes regulated by any financial authority. YieldNodes is NOT trading or speculating or an investment offering in the traditional sense. Its a club of like minded individuals pooling funds to secure blockchain transactions. To learn more refer to: <a href="https://yieldnodes.com" target="_blank">https://yieldnodes.com</a></p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'July2022',
  components: {
  },
  data () {
    return {
      images: [

      ],
      index: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {

  },
  methods: {

  }
}
</script>
