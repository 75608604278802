import MfaModal from '../components/global/MfaVerificationModal'

export default {
  components: {
    MfaModal
  },
  data () {
    return {
      showMfa: false,
      action: null
    }
  },
  methods: {
    verifyMfa (action) {
      this.action = action
      this.showMfa = true
    },
    hideMfa () {
      this.showMfa = false
    }
  }
}
